import DashboardLayout from 'src/theme/layouts/DashboardLayout.vue'

import baseRoutes from '@/routes/dashboard/base.routes.js'

import ordersRoutes from '@/routes/dashboard/orders.routes.js'
import quotationsRoutes from '@/routes/dashboard/quotations.routes.js'
import reservationsRoutes from '@/routes/dashboard/reservations.routes.js'
import customersRoutes from '@/routes/dashboard/customers.routes.js'
import productsRoutes from '@/routes/dashboard/products.routes.js'

import scheduleRoutes from '@/routes/dashboard/schedule.routes.js'
import commissionsRoutes from '@/routes/dashboard/commissions.routes.js'
import revenueRoutes from '@/routes/dashboard/revenue.routes.js'

import agentsRoutes from '@/routes/dashboard/agents.routes.js'
import adminRoutes from '@/routes/dashboard/admin.routes.js'
import newsRoutes from '@/routes/dashboard/news.routes.js'

const dashboardPages = {
  path: '/',
  component: DashboardLayout,
  redirect: '/dashboard',
  name: 'Dashboard layout',
  meta: {
    auth: true
  },
  children: [
    ...baseRoutes,
    ...ordersRoutes,
    ...quotationsRoutes,
    ...reservationsRoutes,
    ...customersRoutes,
    ...productsRoutes,
    ...scheduleRoutes,
    ...commissionsRoutes,
    ...revenueRoutes,
    ...agentsRoutes,
    ...adminRoutes,
    ...newsRoutes
  ]
}

export default dashboardPages
