const NewsList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/News/List.vue')
const NewNews = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/News/New.vue')
const EditNews = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/News/Edit.vue')
const ShowNews = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/News/Show.vue')

export default [
  {
    path: 'news',
    name: 'News',
    components: { default: NewsList }
  },
  {
    path: 'news/nuovo',
    name: 'Nuova News',
    components: { default: NewNews }
  },
  {
    path: 'news/:id/modifica',
    name: 'Modifica News',
    components: { default: EditNews }
  },
  {
    path: 'news/:id/visualizza',
    name: 'Visualizza News',
    components: { default: ShowNews }
  }
]
