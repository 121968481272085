<template>
  <div>
    <div class="rotate-screen-container" v-if="rotateScreenEnabled">
      <div class="rotate-screen-close" @click="closeRotateScreen">
        <img src="/img/close-circle-svgrepo-com.svg">
      </div>
      <div class="rotate-screen-icon">
        <img src="/img/mobile-rotate-rotation-icon.svg">
      </div>
      <div class="rotate-screen-text">
        Per una visione ottimale, si prega di utilizzare lo smartphone/tablet in posizione orizzontale o di utilizzare un dispositivo con uno schermo più grande.
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data () {
    return {
      rotateScreenEnabled: true
    }
  },
  methods: {
    closeRotateScreen () {
      this.rotateScreenEnabled = false
    }
  }
}
</script>

